import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';
import EventModel from '../../../utils/tracking/models';
import { UserConsumer } from '../context/userContext';
import loadable from '@loadable/component';
import clsx from 'clsx';

const FCLink = loadable(() => import('../fc_link/fc_link'));

function MainMenu(props) {
  const [active, setActive] = useState(null);
  const [eventsActive, setEventsActive] = useState(false);
  const [partnershipsActive, setPartnershipsActive] = useState(false);

  const {
    currentIssueCover,
    magazineLink,
    mainMenuVisible,
    fastcoworks,
    scaled,
    config,
    topics,
    closeMenu
  } = props;

  const openSection = section => {
    if (section === active) {
      setActive(null);
    } else {
      setActive(section);
    }
  };

  const openSectionMenuHeight = data => {
    let totalheight;
    let navItemHeight;
    if (data.title === 'collections') {
      const list = document.querySelector(
        '.main-menu__collections-content--collections'
      ).childNodes;
      let collectionsHeight = 0;
      for (let i = 0; i < list.length; i += 1) {
        collectionsHeight += list[i].offsetHeight;
      }
      totalheight = collectionsHeight;
    } else if (data.title === 'fastco works') {
      const list = document.querySelector(
        '.main-menu__collections-content--fastcoworks'
      ).childNodes;
      let collectionsHeight = 0;
      for (let i = 0; i < list.length; i += 1) {
        collectionsHeight += list[i].offsetHeight;
      }
      totalheight = collectionsHeight;
    } else {
      navItemHeight = document.querySelector('.main-menu__collection')
        .offsetHeight;

      totalheight = navItemHeight * data.collectionItems;
    }
    return totalheight;
  };

  const clickSiteCollectionItem = (title, index) => {
    // [DataLayer] trigger nav item click event
    const siteCollectionItemClickEvent = new EventModel(
      'Left Menu Item Click',
      `menu${title}`,
      'leftMenu',
      `leftmenu ${title}`,
      index + 2 // accomodate for hardcoded Homepage item
    );

    siteCollectionItemClickEvent.triggerTrackingEvent(
      'default-click',
      siteCollectionItemClickEvent.getTrackingObject()
    );

    trackGAEvent({
      eventCategory: 'Interaction',
      eventAction: 'Burger Nav Category',
      eventLabel: title
    });

    closeMenu();
  };

  const renderSectionContent = data => {
    if (!data) {
      return null;
    }

    const listLink = data.content.map(info => {
      const { url, title, deck = '', external = false } = info;

      return (
        <li
          key={title}
          className="main-menu__collection"
          onClick={() => clickSiteCollectionItem(title)}
        >
          <FCLink
            to={url}
            title={title}
            config={data.config}
            externalLink={external}
          >
            <span className="main-menu__collection-title">{title}</span>
            {deck.length > 0 ? (
              <p className="main-menu__collection-deck">{deck}</p>
            ) : (
              ''
            )}
          </FCLink>
        </li>
      );
    });

    return listLink;
  };

  return (
    <div
      className={clsx('main-menu', mainMenuVisible ? 'main-menu--opened' : '')}
      onClick={e => e.stopPropagation()}
    >
      <div
        className={`main-menu__brand ${
          scaled ? 'main-menu__brand--scaled' : ''
        }`}
      >
        <button
          className="icon-close"
          onClick={closeMenu}
          aria-label="dropdown close"
        >
          <img
            src="https://assets.fastcompany.com/image/upload/v1694716383/site/icons/close-menu.svg"
            alt="close"
            width="21"
            height="21"
          />
        </button>
      </div>

      <UserConsumer>
        {({ showScreen, isLoggedIn }) => {
          if (isLoggedIn) return null;

          return (
            <span
              className="main-menu__login"
              onClick={e => {
                e.preventDefault();
                showScreen({ screen: 'login', mode: 'modal' });
              }}
            >
              Login
            </span>
          );
        }}
      </UserConsumer>
      <ul className="main-menu__collections-content main-menu__collections-content--menu">
        {topics.map((topic, index) => (
          <li
            key={topic.title}
            className="main-menu__collection"
            onClick={() => clickSiteCollectionItem(topic.title, index)}
          >
            <FCLink
              to={topic.url}
              title={topic.title}
              config={config}
              externalLink={topic.external}
            >
              {topic.eyebrow && (
                <span className="main-menu__eyebrow">{topic.eyebrow}</span>
              )}
              <span className="main-menu__collection-title">
                {topic.title === 'Premium' && <span className="premium-icon" />}
                {topic.title}
              </span>
            </FCLink>
          </li>
        ))}
      </ul>

      <div className="main-menu__dropdown-content">
        <div
          className={
            eventsActive
              ? 'main-menu__collections main-menu__collections--active'
              : 'main-menu__collections'
          }
          onClick={() => {
            setEventsActive(!eventsActive);
          }}
        >
          <span className="main-menu__collections-title">Events</span>
          <span
            className={`main-menu__collections-caret ${
              eventsActive ? 'main-menu__collections-caret--active' : ''
            }`}
          />
        </div>
        <ul
          className={`main-menu__collections-content main-menu__collections-content--collections
        ${eventsActive ? 'main-menu__collections-content--active' : ''} `}
          style={{
            height: eventsActive ? '160px' : 0
          }}
        >
          <li className="main-menu__collection">
            <a
              href="https://events.fastcompany.com/grill_2023"
              target="_blank"
              rel="noopener noreferrer"
              className="main-menu__events-link"
            >
              FC Grill at SXSW
            </a>
          </li>
          <li className="main-menu__collection">
            <a
              href="https://events.fastcompany.com/innovationfestival24"
              className="main-menu__events-link"
            >
              Innovation Festival
            </a>
          </li>
          <li className="main-menu__collection">
            <a
              href="/innovation-festival-360"
              className="main-menu__events-link"
            >
              Innovation Festival 360
            </a>
          </li>
        </ul>
      </div>

      {fastcoworks.map((collection, index) => {
        const { title, sections } = collection;
        const content = sections;
        const collectionsList = title === 'fastco works';
        const collectionItems = content.length;
        return (
          <div
            key={collection.className}
            className="main-menu__dropdown-content"
          >
            <div
              className={`${collection.className} ${
                active === collection.title
                  ? `${collection.className}--active`
                  : ''
              }`}
              onClick={openSection.bind(null, collection.title)}
            >
              <span className="main-menu__collections-title">
                {collection.title}
              </span>
              <span
                className={`main-menu__collections-caret ${
                  active === collection.title
                    ? 'main-menu__collections-caret--active'
                    : ''
                }`}
              />
            </div>
            <ul
              className={`main-menu__collections-content ${
                collectionsList
                  ? 'main-menu__collections-content--fastcoworks'
                  : ''
              } ${
                active === collection.title
                  ? 'main-menu__collections-content--active'
                  : ''
              } `}
              style={{
                height:
                  active === collection.title
                    ? openSectionMenuHeight({ collectionItems, title })
                    : 0
              }}
            >
              {renderSectionContent({ content, index, config })}
            </ul>
          </div>
        );
      })}

      <div className="main-menu__dropdown-content">
        <div
          className={
            partnershipsActive
              ? 'main-menu__collections main-menu__collections--active'
              : 'main-menu__collections'
          }
          onClick={() => {
            setPartnershipsActive(!partnershipsActive);
          }}
        >
          <span className="main-menu__collections-title">Partnerships</span>
          <span
            className={`main-menu__collections-caret ${
              partnershipsActive ? 'main-menu__collections-caret--active' : ''
            }`}
          />
        </div>
        <ul
          className={`main-menu__collections-content main-menu__collections-content--collections
        ${partnershipsActive ? 'main-menu__collections-content--active' : ''} `}
          style={{
            height: partnershipsActive ? '206px' : 0
          }}
        >
          <li className="main-menu__collection">
            <a
              href="/section/fast-company-executive-board"
              className="main-menu__partnerships-link"
            >
              FC Executive Board
            </a>
          </li>
          <li className="main-menu__collection">
            <a href="/fcmicx" className="main-menu__partnerships-link">
              FCMICX
            </a>
          </li>
          <li className="main-menu__collection">
            <a
              href="https://fastcompanypress.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="main-menu__partnerships-link"
            >
              FC Press
            </a>
          </li>
        </ul>
      </div>

      <a
        href="https://www.fastcompany.com/90516811/fast-company-mediakit"
        target="_blank"
        rel="noopener noreferrer"
        className="main-menu__advertise"
      >
        Advertise
      </a>

      <a
        href="https://www.fastcompany.com/newsletters"
        className="main-menu__newsletters"
        onClick={() => {
          trackGAEvent({
            eventCategory: 'Burger',
            eventAction: 'Nav Item',
            eventLabel: 'Newsletters'
          });
        }}
      >
        Newsletters
      </a>

      <a
        href="https://fastcompany.zendesk.com/hc/en-us"
        className="main-menu__helpcenter"
      >
        Help Center
      </a>

      <a href={magazineLink} className="main-menu__current-issue">
        Current Issue
      </a>

      <div className="main-menu__subscription">
        <a href={magazineLink} className="main-menu__mag-cover">
          <img
            alt="Current magazine issue cover"
            width="225"
            height="280"
            src={currentIssueCover}
            loading="lazy"
          />
        </a>
      </div>
    </div>
  );
}

MainMenu.defaultProps = {
  mainMenuVisible: false,
  scaled: false
};

MainMenu.propTypes = {
  fastcoworks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      deck: PropTypes.string
    })
  ).isRequired,
  currentIssueCover: PropTypes.string.isRequired,
  magazineLink: PropTypes.string.isRequired,
  mainMenuVisible: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
  scaled: PropTypes.bool,
  config: PropTypes.shape({}).isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default MainMenu;
